import { SdbProject } from "@custom-types/project-types";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { RequiredRoleBase } from "@utils/access-control/access-control-utils";

/** A single description for roles on the project level. */
interface RequiredRoleProjectLevel extends RequiredRoleBase {
  /**
   * Array filled with company roles where the user,
   * should have one of them in order to have permission.
   * If the array is empty, it means that having a project role is irrelevant,
   * and to have permission you need either a companyRole or another custom
   * role like projectManager.
   */
  projectRoles: CoreAPITypes.EUserProjectRole[];

  /**
   * Defines extra rules for the users that have the Project Manager company role
   */
  projectManager?: {
    /**
     * Allow if the user apart from having the project manager company role
     * is the project manager of the project.
     */
    isProjectManagerOfProject: true;
  };

  /**
   * Defines extra rules for the users that have the Group Manager company role
   */
  companyManager?: {
    /**
     * Apart from being a group manager, allow only for the group that the user belongs to.
     */
    shouldBelongToGroup: true;
  };

  /** List of subscription roles to check in the project context */
  projectSubscriptionRoles?: APITypes.EUserSubscriptionRole[];
}

export interface HasSubscriptionProjectLevelProps {
  /** The available features for the project */
  featuresAvailable?: SphereDashboardAPITypes.IFeature[] | null;

  /** The project context */
  projectContext: SphereDashboardAPITypes.IProjectContextResponse | null;

  /** The required subscription roles for the project */
  requiredProjectSubscriptionRole?: APITypes.EUserSubscriptionRole[];
}

/**
 * Object that determines all different permission rules for users to get
 * access on the project level.
 */
export type RequiredRolesProjectLevel<RoleNameT extends string> = {
  [key in RoleNameT]: RequiredRoleProjectLevel;
};

/**
 * Signature for the function hasUserValidRoleProjectLevel.
 */
export interface HasUserValidRoleProjectLevelProps<RoleNameT extends string> {
  /**
   * The internal name used in the UI to determine certain permissions,
   * e.g. "viewAllProjectMembers" is used to determine whether the user can view the
   * teams tab in the project overview page.
   */
  roleName: RoleNameT;

  /**
   * User to get its project access level.
   */
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;

  /**
   * The project to get the user's permission level.
   */
  selectedProject: SdbProject | null;

  /**
   * You can optionally change the default required roles definition,
   * ! This is only used for unit tests,
   * therefore only use the default value.
   */
  defaultRequiredRolesProjectLevel?: RequiredRolesProjectLevel<RoleNameT>;

  /** Project context for the selected project */
  projectContext?: SphereDashboardAPITypes.IProjectContextResponse | null;
}

/** Names for all permissions or roles within the project level. */
export enum RequiredRoleProjectLevelName {
  /** Whether the user can edit details from a project like name or description */
  canEditProjectDetails = "canEditProjectDetails",

  /** Whether the user can view the cloud activity for a particular project */
  canViewProjectCloudActivity = "canViewProjectCloudActivity",

  /**
   * Whether the user can view the teams tab in project overview page
   * and view all users with access to the project
   */
  canViewAllProjectMembers = "canViewAllProjectMembers",

  /** Whether the user can view the settings for a particular project */
  canViewProjectSettings = "canViewProjectSettings",

  /** Whether the user can change the settings for a particular project */
  canEditProjectSettings = "canEditProjectSettings",

  /** Whether the user can archive projects. */
  canArchiveProjects = "canArchiveProjects",

  /** Whether the user can unarchive projects. */
  canUnarchiveProjects = "canUnarchiveProjects",

  /** Whether the user can share the project where they have a role */
  canInviteUsersToPrivateProject = "canInviteUsersToPrivateProject",

  /** Whether the user can delete a member from a project */
  canDeleteMemberFromProject = "canDeleteMemberFromProject",

  /** Whether the user has permission to view video mode setting */
  canViewVideoModeFeature = "canViewVideoModeFeature",

  /** Whether the user has permission to view 2d images setting */
  canView2dImagesFeature = "canView2dImagesFeature",

  /** Whether the user has permission to view face blurring setting */
  canViewFaceBlurringFeature = "canViewFaceBlurringFeature",

  /** Whether the user has permission to delete a project */
  canDeleteProject = "canDeleteProject",

  /** Whether the user has permission to delete snapshots from a project */
  canDeleteSnapshots = "canDeleteSnapshots",

  /** Whether the user can view the markups (annotations) of a project */
  canViewProjectMarkups = "canViewProjectMarkups",

  /** Whether the user can view the markups (annotations) of a project. Project viewers are allowed */
  canViewProjectMarkupsAllowViewers = "canViewProjectMarkupsAllowViewers",

  /** Whether the user can view the project stats chart in the project overview page */
  canViewProjectsStats = "canViewProjectsStats",

  /** Whether the user can view the project data */
  canViewProjectData = "canViewProjectData",

  /** Whether the user can edit the markup details */
  canEditProjectMarkups = "canEditProjectMarkups",

  /** Whether the user can view data management */
  canViewDataManagement = "canViewDataManagement",
}
