import Grid from "@mui/material/Grid";
import { Alert, FaroButton } from "@faro-lotv/flat-ui";
import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { SphereAvatar } from "@components/header/sphere-avatar";
import {
  FileUploadTaskContext,
  UploadElementType,
  UploadMultipleFilesParams,
  MultiUploadedFileResponse,
} from "@custom-types/file-upload-types";
import { isValidFile } from "@hooks/file-upload-utils";
import { Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { ScanDataFile } from "@pages/project-details/project-data-management/import-data/scan-data-file";
import { useState, useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "@store/store-helper";
import { useFileUpload } from "@hooks/use-file-upload";
import UploadSvg from "@assets/icons/new/upload_50px.svg?react";
import { CLIENT_ID } from "@api/project-api/project-api-utils";

import {
  projectIdsSelector,
  selectedProjectIdSelector,
} from "@store/projects/projects-selector";
import {
  MutationAddPhotogrammetryInput,
  CreateMutationAddPhotogrammetryInput,
  UploadedFileInfo,
  SendAuthenticatedRequestParams,
  useCoreApiTokenProvider,
  TokenProvider,
  sendAuthenticatedRequest,
} from "@faro-lotv/service-wires";
import { FILE_SIZE_MULTIPLIER, sortFiles } from "@utils/file-utils";
import { ProjectId } from "@stellar/api-logic/dist/api/core-api/api-types";
import {
  IElementAreaSection,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { SphereDropdownSelector } from "@components/common/sphere-dropdown-selector";
import { ValueLabelItem } from "@custom-types/types";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { ProjectApi } from "@api/project-api/project-api";
import { runtimeConfig } from "@src/runtime-config";
import { loggedInUserSelector } from "@store/user/user-selector";

const ALLOWED_EXTENSIONS = ["jpg", "JPG"];

export function PhotogrammetryUpload(): JSX.Element | null {
  const [fileList, setFileList] = useState<File[]>([]);
  const projectId: ProjectId = GetProjectID();
  const userId: string = useAppSelector(loggedInUserSelector)?.userId ?? "";
  const tokenProvider: TokenProvider = useCoreApiTokenProvider({
    coreApiBaseUrl: runtimeConfig.urls.apiBaseUrl,
    projectId: projectId.toString(),
    clientId: CLIENT_ID,
  });
  const projectAPI: ProjectApi = useProjectApiClient({ projectId });
  const areas = useRef<IElementAreaSection[]>([]);
  const [areaValues, setAreaValues] = useState<ValueLabelItem<string>[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<
    IElementAreaSection | undefined
  >(undefined);

  const MAX_FILE_SIZE_IN_MB = 20;
  const MAX_FILE_SIZE_IN_BYTE = MAX_FILE_SIZE_IN_MB * FILE_SIZE_MULTIPLIER;
  const { uploadMultipleFiles, validateAndAddFailedTask } = useFileUpload();

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const response = await projectAPI.getIElements({
        types: [IElementType.section],
        typeHints: [IElementTypeHint.area],
      });
      areas.current = response.page.map((e): IElementAreaSection => {
        return e as IElementAreaSection;
      });
      if (areas.current.length > 0) {
        setAreaValues(
          areas.current.map(
            (a: IElementAreaSection): ValueLabelItem<string> => {
              return { label: a.name, value: a.id };
            }
          )
        );
        setSelectedSheet(areas.current[0]);
      } else {
        setAreaValues([{ value: "fail", label: projectId.toString() }]);
      }
    }
    fetchData();
  }, [areas, areaValues, setAreaValues, projectAPI, projectId]);

  function GetProjectID(): ProjectId {
    const pid = useAppSelector(selectedProjectIdSelector);
    const ids: ProjectId = useAppSelector(projectIdsSelector)[0];
    if (pid != null) {
      return pid;
    }
    return ids;
  }

  function onSelectFiles(selectedFiles: FileList, _: () => void): void {
    const combinedFiles = [...fileList, ...selectedFiles];
    setFileList(sortFiles(combinedFiles));
  }

  const SendMutation = useCallback(
    async (
      uploadedResponse: MultiUploadedFileResponse,
      context: FileUploadTaskContext
    ): Promise<void> => {
      const filtereddicts = uploadedResponse.successful.map(
        (uploaded): UploadedFileInfo => {
          const date = new Date();

          const file: UploadedFileInfo = {
            uri: uploaded.downloadUrl,
            name: uploaded.fileName,
            pixelWidth: 4000,
            pixelHeight: 3000,
            duration: null,
            FileName: uploaded.fileName,
            fileSize: uploaded.fileSize,
            md5: uploaded.md5,
            createdAt: date.toUTCString(),
          };
          return file;
        }
      );

      if (selectedSheet === undefined) {
        return;
      }

      const root = (
        await projectAPI.getIElements({ types: [IElementType.projectRoot] })
      ).page[0];

      let timeSeriesId = null;
      const timeSeriesQuery = await projectAPI.getIElements({
        types: [IElementType.timeSeries],
        parentIds: [selectedSheet.id],
      });

      if (timeSeriesQuery.page.length !== 0) {
        timeSeriesId = timeSeriesQuery.page[0].id;
      }

      const mutation: MutationAddPhotogrammetryInput =
        CreateMutationAddPhotogrammetryInput({
          images2d: filtereddicts,
          images360: [],
          videos2d: [],
          videos360: [],
          rootId: root.id,
          targetSheetId: selectedSheet.id,
          timeseriesId: timeSeriesId,
        });

      await projectAPI.applyMutations([mutation]);
      setFileList([]);

      const payload = {
        projectId: projectId.toString(),
        userId,
        datasetId: mutation.dataset?.id,
      };

      const requestParams: SendAuthenticatedRequestParams = {
        baseUrl: "https://hb-pre-alignment-batch-com-dev.azurewebsites.net",
        path: "api/photogrammetry-trigger",
        tokenProvider,
        requestBody: JSON.stringify(payload),
        httpMethod: "POST",
      };

      await sendAuthenticatedRequest(requestParams);
    },
    [projectAPI, userId, projectId, tokenProvider, selectedSheet, setFileList]
  );

  const uploadFiles = useCallback(async (): Promise<void> => {
    if (!projectId) {
      return;
    }

    const context: FileUploadTaskContext = {
      uploadElementType: UploadElementType.project,
      projectId,
    };

    const uploadableFiles = fileList.filter((file) =>
      validateAndAddFailedTask({
        file,
        allowedExtensions: ALLOWED_EXTENSIONS,
        maxFileSize: MAX_FILE_SIZE_IN_BYTE,
        context,
      })
    );

    // Return if there is no uploadable file
    if (!uploadableFiles.length) {
      return;
    }

    const uploadParams: UploadMultipleFilesParams = {
      files: uploadableFiles,
      onUploadStart: () => undefined,
      onUploadProgress: () => undefined,
      onUploadComplete: SendMutation,
      context,
    };

    await uploadMultipleFiles(uploadParams);
  }, [
    fileList,
    projectId,
    uploadMultipleFiles,
    validateAndAddFailedTask,
    MAX_FILE_SIZE_IN_BYTE,
    SendMutation,
  ]);

  function handleareaChange(value: string): void {
    const element = areas.current.find((a: IElementAreaSection) => {
      return a.id === value;
    });
    setSelectedSheet(element);
  }

  function removeFile(index: number): void {
    const allFiles = [...fileList];
    allFiles.splice(index, 1);

    setFileList(sortFiles(allFiles));
  }

  return (
    <Grid maxWidth="100%" width="70vw">
      <Alert
        title="For this Beta version you can only upload jpg images."
        variant="info"
        sx={{
          marginBottom: SPACE_ELEMENTS_OF_MODAL,
          backgroundColor: sphereColors.blue100,
          color: sphereColors.black,
        }}
      />
      <Stack>
        <text>Sheet</text>

        <SphereDropdownSelector
          items={areaValues}
          currentValue={selectedSheet?.name ?? "Select Area"}
          handleChange={handleareaChange}
        />
        <SphereDropzone
          instruction="Drag & drop"
          maxFileSize={MAX_FILE_SIZE_IN_BYTE}
          shouldShowSupportedFormats={false}
          shouldShowSizeLimit={false}
          shouldAllowMultiUpload={true}
          avatar={
            <SphereAvatar
              icon={<UploadSvg />}
              size="x-large"
              shouldHideWhiteRim
              iconColor={sphereColors.gray600}
              backgroundColor={sphereColors.gray100}
            />
          }
          allowedExtensions={ALLOWED_EXTENSIONS}
          isLoading={false}
          setIsLoading={() => undefined}
          onUploadComplete={SendMutation}
          context={{
            uploadElementType: UploadElementType.default,
            projectId: projectId ?? "",
          }}
          onSelectFiles={onSelectFiles}
        />
      </Stack>

      {fileList.length !== 0 && (
        <Stack
          sx={{
            my: SPACE_ELEMENTS_OF_MODAL,
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {fileList.sort().map((file, index) => (
            <ScanDataFile
              key={index}
              fileName={file.name}
              fileSize={file.size}
              onDelete={() => removeFile(index)}
              isValid={
                isValidFile({
                  file,
                  allowedExtensions: ALLOWED_EXTENSIONS,
                  maxFileSize: MAX_FILE_SIZE_IN_BYTE,
                }).isValid
              }
            />
          ))}
        </Stack>
      )}
      <FaroButton onClick={uploadFiles} disabled={fileList.length === 0}>
        Import data
      </FaroButton>
    </Grid>
  );
}
