import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { Team } from "@custom-types/teams-types";
import {
  AddTeamProps,
  FetchTeamDetailsProps,
  RemoveTeamMembersProps,
  RemoveTeamMembersResult,
} from "@store/teams/teams-slice-types";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { mockedTeams } from "@store/teams/teams-mocks";
import { CoreAPITypes } from "@stellar/api-logic";
import { BaseCoreApiClientProps } from "@store/store-types";
import { teamAdapter } from "@store/teams/teams-slice";
import { BaseTeamIdProps } from "@custom-types/sdb-company-types";

export const fetchTeams = createAsyncThunk<
  Team[],
  void,
  {
    state: RootState;
  }
>(
  "teams/fetchTeams",
  async () => {
    const mockedWaitTime = 1000;
    return new Promise<Team[]>((resolve) =>
      setTimeout(() => resolve(mockedTeams), mockedWaitTime)
    );
  },
  {
    condition: (_, api) => {
      // Only fetch if the isAlphaTestingEnabled is true
      return api.getState().ui.isAlphaTestingEnabled;
    },
  }
);

/** Fetches the details of a team */
export const fetchTeamDetails = createAsyncThunk<Team, FetchTeamDetailsProps>(
  "teams/fetchTeamDetails",
  async ({ coreApiClient, companyId, teamId }) => {
    try {
      // TODO: Fetch team details from backend https://faro01.atlassian.net/browse/ST-2379
      // const fetchedTeamDetails = await coreApiClient.V3.SDB.getTeamById(
      //   companyId,
      //   teamId
      // );

      return mockedTeams.find((team) => team.id === teamId) ?? mockedTeams[0];
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Creates a team in the backend and adds it to the store */
export const createTeam = createAsyncThunk<Team, AddTeamProps>(
  "teams/createTeam",
  async ({
    coreApiClient,
    companyId,
    teamName,
    description,
    selectedMemberIds,
  }) => {
    try {
      // TODO: Add backend request to the backend https://faro01.atlassian.net/browse/ST-2379
      // const team = await coreApiClient.V3.SDB.createTeam(companyId, {
      //   name: teamName,
      //   description,
      //   members: [
      //     {
      //       identities: selectedMemberIds,
      //     },
      //   ],
      // });

      const oneSecondInMilliseconds = 1000;
      const team = new Promise<Team>((resolve) =>
        setTimeout(
          () =>
            resolve({
              id: `${Date.now().toString()}`,
              name: teamName,
              description: description,
              createdAt: "2023-09-17T12:00:00Z",
              sampleMembers: [
                {
                  userResponse: {
                    id: `${Date.now().toString()}`,
                    identity: `${Date.now().toString()}`,
                    lastName: `LastName${Date.now().toString()}`,
                    firstName: `FirstName${Date.now().toString()}`,
                    email: `user${Date.now().toString()}@example.com`,
                    role: CoreAPITypes.EUserCompanyRole.member,
                  },
                  createdAt: "2023-09-17T12:00:00Z",
                },
              ],
            }),
          oneSecondInMilliseconds
        )
      );

      return team;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface UpdateTeamDetailsProps
  extends BaseCoreApiClientProps,
    BaseTeamIdProps {
  /** The payload for updating team details */
  payload: { name: string };
}

/** Update team details */
export const updateTeamDetails = createAsyncThunk<
  Team,
  UpdateTeamDetailsProps,
  {
    state: RootState;
  }
>(
  "teams/updateTeamDetails",
  async ({ coreApiClient, teamId, payload }, { getState }) => {
    const { teams } = getState();

    /**
     * The ID of the team that is going to be updated.
     * If teamId is not provided, the selectedTeamId from store is selected
     */
    const updatingTeamId = teamId || teams.selectedTeamId;

    if (!updatingTeamId) {
      throw new Error("No teamId exist to updateTeamDetails");
    }

    const team = teamAdapter.getSelectors().selectById(teams, updatingTeamId);
    if (!team) {
      throw new Error("Team not found");
    }

    try {
      // TODO: Add backend request to update team details https://faro01.atlassian.net/browse/ST-2379
      // const updatedTeam = await coreApiClient.V3.SDB.updateTeamDetails({
      //   teamId: updatingTeamId,
      //   payload,
      // });

      const updatedTeam = { ...team, ...payload };
      return updatedTeam;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Remove a member from the team using the backend,
 * and then removes it from team in the store as well
 */
export const removeMembersFromTeam = createAsyncThunk<
  RemoveTeamMembersResult,
  RemoveTeamMembersProps
>(
  "teams/removeMembersFromTeam",
  async ({ coreApiClient, companyId, teamId, memberIds }) => {
    try {
      // TODO: Add backend request to remove a member https://faro01.atlassian.net/browse/ST-2379
      // await coreApiClient.V3.SDB.removeTeamMember({
      //   companyId,
      //   teamId,
      //   members: memberIds,
      // });

      return {
        teamId,
        companyId,
        memberIds,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);
