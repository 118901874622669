import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { BaseTeamProps, TeamMemberBase } from "@custom-types/teams-types";
import { MembersTable } from "@components/table/members/members-table";
import { useAppSelector } from "@store/store-helper";
import { fetchingStatusTeamsSelector } from "@store/teams/teams-selector";
import { FetchingStatus } from "@store/store-types";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { useMemo } from "react";
import { getPrettyName } from "@utils/user-utils";
import { TeamDetailsInfoBar } from "@pages/members/teams/team-details/team-details-info-bar";

interface Props extends Partial<BaseTeamProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Contains the Members tab of the team details
 */
export function TeamMembers({ team, isLoading = false }: Props): JSX.Element {
  const { companyId } = useAppParams();
  const fetchingStatus = useAppSelector(fetchingStatusTeamsSelector);

  // Constructing the members list for members table from sample members of a team
  const teamMembers: TeamMemberBase[] = useMemo(() => {
    if (!team) {
      return [];
    }

    return team.sampleMembers.map((sampleMember) => ({
      ...sampleMember.userResponse,
      name: getPrettyName(sampleMember.userResponse),
    }));
  }, [team]);

  if (!companyId) {
    return <NotFoundPage />;
  }

  return (
    <>
      <TeamDetailsInfoBar team={team} isLoading={isLoading} />

      <MembersTable
        companyId={companyId}
        members={teamMembers}
        team={team}
        tableSubject="team"
        isLoading={fetchingStatus === FetchingStatus.pending}
        requiredColumns={[
          MemberHeaders.avatar,
          MemberHeaders.user,
          MemberHeaders.email,
          MemberHeaders.status,
          MemberHeaders.options,
        ]}
      />
    </>
  );
}
