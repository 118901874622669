import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FetchingStatus } from "@store/store-types";
import {
  createTeam,
  fetchTeamDetails,
  fetchTeams,
  removeMembersFromTeam,
  updateTeamDetails,
} from "@store/teams/teams-slice-thunk";
import { Team, TeamState } from "@custom-types/teams-types";
import { removeLocalMemberFromTeam } from "@store/teams/teams-slice-utils";

export const teamAdapter = createEntityAdapter<Team>({
  selectId: (team) => team.id,
});

export const initialState: TeamState = {
  ...teamAdapter.getInitialState(),
  status: FetchingStatus.uninitialized,
  selectedTeamId: null,
  fetching: {
    isRemovingTeamMember: false,
  },
};

const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    resetTeamState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(fetchTeams.fulfilled, (state, action: PayloadAction<Team[]>) => {
        state.status = FetchingStatus.succeeded;
        teamAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchTeams.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(createTeam.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.status = FetchingStatus.succeeded;
        teamAdapter.setOne(state, action.payload);
      })
      .addCase(createTeam.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(fetchTeamDetails.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(fetchTeamDetails.fulfilled, (state, action) => {
        state.selectedTeamId = action.payload.id;
        state.status = FetchingStatus.succeeded;
        teamAdapter.setOne(state, action.payload);
      })
      .addCase(fetchTeamDetails.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(updateTeamDetails.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(updateTeamDetails.fulfilled, (state, action) => {
        state.selectedTeamId = action.payload.id;
        state.status = FetchingStatus.succeeded;
        teamAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateTeamDetails.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(removeMembersFromTeam.pending, (state) => {
        state.fetching.isRemovingTeamMember = true;
      })
      .addCase(removeMembersFromTeam.fulfilled, (state, action) => {
        removeLocalMemberFromTeam(state, action.payload);
        state.fetching.isRemovingTeamMember = false;
      })
      .addCase(removeMembersFromTeam.rejected, (state) => {
        state.fetching.isRemovingTeamMember = false;
      });
  },
});

export const { resetTeamState } = teamSlice.actions;

export const teamReducer = teamSlice.reducer;
